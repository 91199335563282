var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "addDocumentModal",
        size: "lg",
        "ok-only": "",
        "ok-title": "Chiudi",
        "ok-variant": "lisaweb",
        "header-bg-variant": "lisaweb",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { slot: "modal-title" },
                  slot: "modal-title",
                },
                [
                  _c("b-icon", { attrs: { icon: "file-earmark" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Carica Documento")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    title: "Chiudi",
                    "data-dismiss": "modal",
                  },
                  on: {
                    click: function ($event) {
                      cancel()
                      _vm.close()
                    },
                  },
                },
                [_vm._v("\n      ×\n    ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("validation-observer", {
        ref: "observer2",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ handleSubmit, invalid }) {
              return [
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "b-card",
                      {
                        attrs: {
                          header: "Carica Documento",
                          "header-tag": "header",
                        },
                      },
                      [
                        _c(
                          "b-card-text",
                          [
                            _c("b-row", [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-4" },
                                [
                                  _c("base-input", {
                                    attrs: {
                                      vid: "title",
                                      name: _vm.beFormCreate.title.label,
                                      label: _vm.beFormCreate.title.label,
                                      placeholder: _vm.beFormCreate.title.label,
                                      rules: _vm.getRules("title", "document"),
                                    },
                                    model: {
                                      value: _vm.form.title,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "title", $$v)
                                      },
                                      expression: "form.title",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-4" },
                                [
                                  _c("base-select", {
                                    attrs: {
                                      vid: "document_type",
                                      name: _vm.beFormCreate.document_type
                                        .label,
                                      label:
                                        _vm.beFormCreate.document_type.label,
                                      options:
                                        _vm.beFormCreate.document_type.options,
                                      rules: _vm.getRules(
                                        "document_type",
                                        "document"
                                      ),
                                    },
                                    model: {
                                      value: _vm.form.document_type,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "document_type", $$v)
                                      },
                                      expression: "form.document_type",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-4" },
                                [
                                  _c("base-textarea", {
                                    attrs: {
                                      vid: "note",
                                      name: _vm.beFormCreate.note.label,
                                      label: _vm.beFormCreate.note.label,
                                      placeholder: "Inserisci una nota",
                                      rules: _vm.getRules("note", "document"),
                                    },
                                    model: {
                                      value: _vm.form.note,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "note", $$v)
                                      },
                                      expression: "form.note",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("b-row", [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c("base-form-file", {
                                    attrs: {
                                      vid: "base_form_file",
                                      name: "Carica File",
                                      label: "Carica  File",
                                      rules: { required: true },
                                    },
                                    model: {
                                      value: _vm.file,
                                      callback: function ($$v) {
                                        _vm.file = $$v
                                      },
                                      expression: "file",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-4 mt-4" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        size: "sm",
                                        variant: "lisaweb",
                                        disabled: _vm.file ? false : true,
                                        title: "Cancella File",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.file = null
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                Cancella File\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        staticClass: "mt-2 float-right",
                        attrs: {
                          type: "button",
                          disabled: invalid,
                          variant: "outline-lisaweb",
                          size: "sm",
                        },
                        on: {
                          click: function ($event) {
                            return handleSubmit(_vm.upload)
                          },
                        },
                      },
                      [_vm._v("\n        Carica\n      ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }